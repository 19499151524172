<template>
  <div>
    <!-- 学员信息 -->
    <stu-info @select="handleStudentSelected" />

    <div class="margin-top padding bg-white">
      <border-title titleName="余额信息" />
      <div v-show="!isShow">
        <div class="margin-top text-bold">
          <span class="price-tag">￥</span>
          <span class="margin-left-sm">
            账户剩余：
            <span>{{overRecharge}}</span>
            元
          </span>
        </div>
        <div class="padding-tb-sm">
          充值余额：
          <el-input-number v-model="rechargeForm.money" controls-position="right" :min="0" :max="999999999" size="mini" />
          <span class="text-grey margin-left-xs">元</span>
        </div>
        <div class="tip padding-top flex aligin-items-center justify-content-between">
          <div class="flex aligin-items-center tip-item flex1">
            <div class="text-grey">对内备注</div>
            <div class="margin-left tip-item-input"><el-input v-model="rechargeForm.order_in_remark" placeholder="该备注只有内部操作人员可见" size="mini" /></div>
          </div>
          <div class="flex aligin-items-center tip-item flex1">
            <div class="text-grey">对外备注</div>
            <div class="margin-left tip-item-input"><el-input v-model="rechargeForm.order_outer_remak" placeholder="该备注将会被打印且会在校宝家中显示" size="mini" /></div>
          </div>
        </div>
      </div>

      <div class="no-curriculums" v-show="isShow">
        <i class="el-icon-circle-plus-outline"></i>
        <p>请点击左上方按钮选择报名信息</p>
      </div>
    </div>

    <!-- 支付信息 -->
    <pay-info v-show="!isShow" :isRecharge="false" :totalPrice="rechargeForm.money" @change="payChange" @schoolChange="schoolChange" />
    <!-- 订单标签 -->
    <order-tag @change="orderChange" />
    <!-- 经办信息 -->
    <handle-info :isSales="false" @change="handelChange" />

    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn"><el-button v-preventReClick="1000" type="primary" size="medium" @click="submite">保存</el-button></div>
    </div>
  </div>
</template>

<script>
import StuInfo from '../components/stuInfo.vue';
import HandleInfo from '../components/handleInfo.vue';
import OrderTag from '../components/orderTag.vue';
import PayInfo from '../components/payInfo.vue';
export default {
  components: {
    StuInfo,
    OrderTag,
    HandleInfo,
    PayInfo
  },
  data() {
    return {
      tipInput: '',
      num: '0',
      isShow: true,
      submitForm: {
        Curriculums: []
      },
      overRecharge: 0,
      rechargeForm: {
        collections: [
          {
            pay_type: 0, // 付款类型
            opposite_account_number: '', // 对方账号
            serial_number: '', // 流水编号 ,
            money: 0, // 收支金额
            remark: '',
            source: 0 //付款记录来源 对应枚举 PayTypeSorce
          }
        ],
        student_id: 0, // 学员id
        money: 0, // 充值金额
        order_price: 0, // 订单总价
        order_in_remark: '', // 对内备注
        order_outer_remak: '', // 对外备注
        order_tags: '', // 订单标签(逗号隔开)
        school_id: 0, // 订单添加所属校区id
        sale_user: 0, // 销售员id ,
        handle_school: 0, //  经办校区id ,
        handle_date: '' // 经办日期
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    //选择了学员
    handleStudentSelected(student) {
      if (student) {
        this.isShow = false;
        this.rechargeForm.student_id = student.id;
        this.API.GetBalance(student.id).then(res => {
          this.overRecharge = res.data.toFixed(2)
        })
      }
    },
    // 经办校区选择
    schoolChange(e) {
      this.rechargeForm.school_id = e;
      this.rechargeForm.handle_school = e;
    },
    // 支付信息
    payChange(e) {
      this.rechargeForm.collections = e.payList;
    },
    // 日期选择
    handelChange(e) {
      this.rechargeForm.handle_date = e.handle_date;
      this.rechargeForm.handle_user = e.handle_user;
    },
    // 订单标签选择
    orderChange(e) {
      this.rechargeForm.order_tags = e.join(',');
    },
    // 提交
    submite() {
      console.log(this.rechargeForm);
      this.API.BalanceRecharge(this.rechargeForm).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.$router.go(-1)
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>

<style scoped>
.tip {
  width: 100%;
}
.tip-item {
  width: 100%;
}
.tip-item-input {
  width: 85%;
}
.sign-up-bottom {
  bottom: 0;
  right: 0;
  position: fixed;
}
.no-curriculums {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
</style>
